
import { ChangeDetectorRef, Provider, Type, inject } from '@angular/core';
import { ControlValueAccessor, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';

export function provideValueAccessor(cmp: Type<ControlValueAccessor>): Provider {
  return { provide: NG_VALUE_ACCESSOR, useExisting: cmp, multi: true };
};

export function provideValidator(cmp: Type<Validator>): Provider {
  return { provide: NG_VALIDATORS, useExisting: cmp, multi: true };
}

export function provideAsyncValidator(cmp: Type<Validator>): Provider {
  return { provide: NG_ASYNC_VALIDATORS, useExisting: cmp, multi: true };
}


export abstract class SimpleValueAccessor<ExternalType, InternalType = ExternalType> implements ControlValueAccessor {
  protected cdRef = inject(ChangeDetectorRef);

  private _value: InternalType;
  private _disabled: boolean;

  public get value(): InternalType { return this._value; }
  protected set value(v: InternalType) { this._value = v; }

  public get disabled(): boolean { return this._disabled; }
  protected set disabled(v: boolean) { this._disabled = v; }

  protected onChange: (v: ExternalType) => void = () => { };
  protected onTouched: () => void = () => { };

  protected isValidInput(obj: any): obj is ExternalType { return true; }
  protected afterDisabledChanged(disabled: boolean) { }

  abstract writeValue(obj: any): void;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.afterDisabledChanged(isDisabled);
    this.cdRef.markForCheck();
  }

}
