import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from "@cawita/core-front/api";
import { Role } from "./role.model";
import type { LockType } from "./user-lock.model";

const UserSearchMapper = (s) => ({
    or: [
        { firstName: { regexp: s } },
        { lastName: { regexp: s } },
        { email: { regexp: s } },
    ]
})

@CwtModel({ name: 'user' })
@CwtCrudContract({ path: '/user', searchMapper: UserSearchMapper })
@CwtCrudContract({ path: 'company/:companyId/user', searchMapper: UserSearchMapper, identifier: 'company' })
export class User extends CwtApiEntity {
    @CwtProp() firstName?: string;
    @CwtProp() lastName?: string;
    @CwtProp() email?: string;
    @CwtProp() emailValid?: boolean;
    @CwtProp() phoneValid?: boolean;
    @CwtProp() accessType?: string;
    @CwtProp({ ref: Role }) role?: Role;
    @CwtProp() lockType: LockType;
    @CwtProp() connexionTokens?: Partial<ConnexionToken>[];
    @CwtProp() picture: string;
    @CwtProp() civility:UserCivility;
}

export type Lock = {
    adminLock: boolean;
    lockUntil: Date;
    lockingTime: Date;
    loggingAttempt: number;
}

export type ConnexionToken = {
    ip: string;
    userAgent: string;
    creationDate: Date;
    expirationDate: Date;
}

export enum UserCivility {
    MR = "mr",
    MRS = "mrs",
}
