import { CwtApiEntity, CwtCrudContract, CwtModel, CwtProp } from '@cawita/core-front/api';
import { User } from './user.model';
import { Address } from './address.model';

const customerSearchMapper = (s) => ({
    or: [
        { name: { regexp: s } }
    ]
});


@CwtModel({ name: 'customer'})
@CwtCrudContract({ path: 'customer', searchMapper:customerSearchMapper })

export class Customer extends CwtApiEntity {
    @CwtProp({ ref: User }) user: User;
    @CwtProp() name: string;
    @CwtProp() address: Address;
    @CwtProp() type: CustomerType;
}

export enum CustomerType {
    B2B = "b2b",
    B2C = "b2c",
}