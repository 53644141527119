import { Injectable } from '@angular/core';
import { getId } from '@cawita/core-front';
import { CwtApiService, CwtRequestConfig } from '@cawita/core-front/api';
import { Customer, CustomerType, User } from '@shared/models';
import { map, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomerAPI {
    constructor(private api: CwtApiService) { }

    public createCustomer(payload:Partial<Customer>, type:CustomerType,config?:CwtRequestConfig): Observable<Customer>{
        return type === "b2b" ? 
            this.api.post(`customer/b2b`, payload, config)
            :
            this.api.post(`customer/b2c`, payload, config)
    }

    public createExistingCustomerB2b(payload:any,config?:CwtRequestConfig): Observable<Customer>{
        return this.api.post(`customer/b2b/existing-customer`, payload, config);
    }

    public updateCustomer(customer:Customer, payload:Partial<Customer>,config?:CwtRequestConfig): Observable<Customer>{
        return this.api.patch(`customer/${getId(customer)}`, payload, config);
    }


}
